import { render, staticRenderFns } from "./Send-Mail.vue?vue&type=template&id=145ec4f9&scoped=true"
import script from "./Send-Mail.vue?vue&type=script&lang=js"
export * from "./Send-Mail.vue?vue&type=script&lang=js"
import style0 from "./Send-Mail.vue?vue&type=style&index=0&id=145ec4f9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "145ec4f9",
  null
  
)

export default component.exports